<template>
  <default-layout>
    <v-container id="user-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <!--      <account-type-list-->
      <!--        :items="accountTypes"-->
      <!--        :meta="meta"-->
      <!--        :permissions="permissions"-->
      <!--        @changePage="changePage"-->
      <!--      ></account-type-list>-->
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
// const AccountTypeList = () => import('@/components/user-management/account-type/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    // AccountTypeList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.accountType.permissions,
      accountTypes: (state) => state.accountType.accountTypes,
      meta: (state) => state.accountType.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('accountType/RESET_DEFAULT');
      const response = await this.$store.dispatch('accountType/getInitData', query);
      console.log('CHECK ACCOUNT TYPE INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      try {
        this.isLoading = true;
        let query = this.$route.query;
        query.page = page;
        const response = await this.$store.dispatch('accountType/getData', query);
        console.log('GET ACCOUNT TYPE DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
